import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import Select from 'react-select';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
          userlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',

          selectedFilOption:'',
          selectedFilvalue:'',
         
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
    this.listloadedData()
   }


  listloadedData(postobject='') {
  var qs = require('qs');
   if(postobject!=''){
    var postobject = postobject
   }else{
    var postobject = {
      activePage: this.state.activePage,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
   }
  
 axios.post(apiUrl+"user/list",qs.stringify(postobject)).then(res => {
   
   if(res.data.status === 'success'){
       this.setState({  userlist: res.data, totalRecord: res.data.records_count}, function() {
         this.userlist();
       });
   }
   else{         
    this.setState({  userlist: '', totalRecord: 0}, function() {            
      this.userlist();
    });
  }
 }); 
}

  
 
 
  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  var postObject = {             
    activePage   : this.state.activePage,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
    

}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          filterVal : formPayload.selectedFilvalue,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

// deleteuser(id){
//         var qs = require('qs');
//         var postObject = {
//           admin_id : localStorage.getItem("admin_id"),
//           user_id  :id
//         };
//         axios.post(apiUrl+"user/list",qs.stringify(postObject)).then(res => {      
//           if(res.data.status === 'success'){
//               this.setState({  userlist: res.data, totalRecord: res.data.records_count}, function() {
//                 this.userlist();
//               });
//           }
//         }); 
// }

activateuser(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :this.state.selectid,
		  user_status: stat
        };
        axios.post(apiUrl+"user/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}


confirmPopup = (id, status) => {
  var status = status;
  if(status == 'inactive'){
    status = "active";
  }else{
    status = "inactive";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activateuser();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"user/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}
  userlist() {
// console.log(this.state.userlist,'userlist')
    var userlist = this.state.userlist;
	
    if (userlist != "undefined" && userlist != null) {
      if (userlist.status == "success") {
        if (Object.keys(userlist).length > 0) {
          const userlistDetails = userlist.userlist.map(
            (userlist, Index) => {
              let sno = Index+1;
              if(userlist.status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var mobile = ((userlist.mobile == null)||(userlist.mobile == '')) ?  userlist.mobile = 'N/A' : userlist.mobile;
              return (
                <tr key={userlist.id}>
                <td>{sno}</td>
                  <td>{userlist.firstname} {(userlist.lastname != '') ? userlist.lastname : ''}</td>
				          <td>{userlist.username}</td>
				          <td>{userlist.orgpwd}</td>
                  {/* <td>{userlist.domain_name}</td>*/}
                  <td>{userlist.email}</td> 
				          <td>{mobile}</td>
                  <td>{lang.Capitalize(status)}</td>
                 
                  <td className="actiongroup">
                     <Link to={{ pathname: '/user-view/'+userlist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                     <Link to={{ pathname: '/user-edit/'+userlist.id}} className="" title="Duplicate">  <i className="ft-edit" aria-hidden="true"></i></Link>
                     {(status == 'active'?<Link onClick={this.confirmPopup.bind(this, userlist.id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, userlist.id, status)} className="" title="Status"><i className="ft-lock" aria-hidden="true"></i></Link>)}          
                  </td>
                </tr>
              );
            }
          );
          return userlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  handleChange = (selOption) => {       	
    this.setState({selectedFilOption:selOption})
    this.setState({  selectedFilvalue : selOption.value},function(){
      this.handleFormSubmit()
    });
  };
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the user?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="user" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            <h3>{this.state.totalRecord} User{this.state.totalRecord>1 ? 's' : ''}</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="input_search">
                    <div className="search-inner search_input">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                    </div>  
                    <div className="search-inner search_select">
                    <Select name="category" options={lang.common.status_filter_option} 
                      //onChange={this.handleInputChange}
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>    
                  </div>
          </form>
                </div>
          <div className="add_button">  
      		 <a className="primary-btn" href='/user-add'>Add</a> 
            {/* &nbsp;|&nbsp; */}
            &nbsp;&nbsp;
			 {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>*/}
           <Link onClick={this.exportdata.bind(this)} className="primary-btn" title="Export"> Export</Link>
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>			          
                <th>Name</th>
			          <th>Username</th>    
                <th>Password</th>
                <th>Email</th>
                <th>Contact</th>
                {/* <th>Started Year</th> */}
                <th>Status</th>
                <th>Action</th>
                
              </tr>
            </thead>
            <tbody>{this.userlist()}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default List;