import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import {
  Table
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {PageTitle } from "../Helpers/SettingHelper";
class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      selectid:'',
      admin_id: localStorage.getItem("admin_id")
    };

      

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Templates');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
      this.listloadData()
   }
listloadData(postobject=''){
  var qs = require('qs');
  if(postobject!=''){
    var postobject=postobject;
  }else{
    var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem("admin_id")
    };
  }
      
    axios.post(apiUrl+"template/list",qs.stringify(postobject)).then(res => {
     
      if(res.data.status === 'success'){
        this.setState({ totalRecord : res.data.records_count, templatelist: res.data.templatelist }, function() {
          this.templatelist();
        });
      }
    }); 
}
   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    this.listloadData(postobject)
    }

    handleInputChange(event) {
      var admin_id = localStorage.getItem("admin_id");
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    var postobject = {
      search_all: value,
      activePage : 1,
      admin_id: admin_id
    };
    this.listloadData(postobject)

  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getTemplateList(qs.stringify(postObject));
          this.listloadData(postObject)

    }

   componentWillReceiveProps(Props){

    

   }
   confirmPopup = (id) => {
    var status = status;
    if(status == 'inactive'){
      status = "active";
    }else{
      status = "inactive";
    }
    this.setState({selectid: id})	
    $('.confirm-action').addClass('show');		
  }
  
  Confirmstatus = (confirmstatus) => {
    if(confirmstatus == 'yes'){
      this.deletetemplate();
      $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
      
  }
deletetemplate(){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          email_id  : this.state.selectid,
          admin_id  : admin_id
        };

      axios.post(apiUrl+"template/delete",qs.stringify(postObject)).then(res => {
     
        if(res.data.status === 'success'){
                      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
                      setTimeout(
                      function() {
                      $('.success_message').html('');
                      }
                      .bind(this),
                      3000
                      );

                      this.listloadData()
                   
            }   
        
      }); 

}
templatelist() {
    var templatelist = this.state.templatelist;
    if (templatelist != "undefined" && templatelist != null) {
        if (Object.keys(templatelist).length > 0) {
          const templatelistDetails = templatelist.map(
            (templatelist, Index) => {
              let sno = Index+1;
              return (
                <tr key={templatelist.email_id}>
                <td>{sno}</td>
                  <td>
                  {templatelist.email_name}
                  </td>
                  <td>
                   <Link to={{ pathname: '/templates-edit/'+templatelist.email_id}} className="templateedit" title="edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                   <Link onClick={this.confirmPopup.bind(this, templatelist.email_id)} className="templateedit" title="delete">  <i className="ft-trash-2" aria-hidden="true"></i></Link>

                  </td>
                </tr>
              );
            }
          );
          return templatelistDetails ;
        } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action">
			<p>Are you sure! you want to delete the template?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="templates" />  
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
            <div className="car-listing-wrap leaa-admin-head card">
             <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>{this.state.totalRecord} Template{this.state.totalRecord>1 ? 's' : ''} </h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="primary-btn" href='/template/add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Template Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.templatelist()}</tbody>
                </Table>
                 <div className="text-center">
           <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;