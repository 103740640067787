import React, { Component } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Table,

} from "react-bootstrap"; 
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      list:'',
      totalRecord:0
    };

  
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    
    }

    

    componentDidMount() {
      var admin_id = localStorage.getItem("admin_id");
      console.log(admin_id, 'admin_id from Dashboardmanagement 123');
      var qs = require('qs');
        var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
        };


        axios.post(apiUrl+"dashboardmanagment/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
          
            this.setState({  list: res.data.list, totalRecord: res.data.records_count}, function() {
            
              this.dasboard();
            });

        }else{
          this.setState({  list: '', totalRecord: 0}, function() {
            
            this.dasboard();
          });

        }
        }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getlist(qs.stringify(postobject))
    axios.post(apiUrl+"dashboardmanagment/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  list: res.data.list, totalRecord: res.data.records_count}, function() {
            this.list();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id         
  };
  //console.log(postObject);
  this.props.getFormList(qs.stringify(postObject));

} 



dasboard() {

  var list = this.state.list;
  console.log(list,'list 123')
  if (list != "undefined" && list != null) {

  

      if (Object.keys(list).length > 0) {
        const vehiclelistDetails = list.map(
          (list, Index) => {
            let sno = Index+1;
       

           var istyle = { width: 100};
            return (
              <tr key={list.id}>
                  <td>{sno}</td>
                  <td>{list.usertype}</td>
                   {localStorage.getItem('loggedas') === 'admin' &&
                    <> 
                  <td className="actiongroup">
                    <Link to={{ pathname: '/dashboardmanagement-edit/'+list.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>

                    {/* <Link to={{ pathname: '/dashboardmanagement-edit/'+list.id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link> */}
                     {/* {(status == 'Active'?<Link onClick={this.activateuser.bind(this, list.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, list.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}      */}
                  </td>
                  </>}
              </tr>
            );
          }
        );
        return vehiclelistDetails;
      }else {

      return (
        <tr>
          <td colspan="6" className="v-align-nr">
            No Result
          </td>
        </tr>
      );

    }
  }

}
  


  render() {

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboardmanagement" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card vi-2">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                <h3>{this.state.totalRecord} Dashboard management </h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                 {/* <div className="add_button">  
                    <a className="" href='/dashboardmanagement-add'>Add</a>
                </div> */}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
              <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Login Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.dasboard()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  {/* <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  /> */}
                </div>
              </div>

            </div> 
        </div>  
      </div>
    </div>
    );
  }
}
export default (withRouter(List));