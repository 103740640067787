import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Background from '../../common/images/side-bg.jpg';
import loginImg from "../../common/images/login-logo.png";
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id'),
        st_plugin_for: '',
		};
		
    }

    componentDidMount() {
    
      this.check_plugin_for()
   }

   check_plugin_for = () => {
      var qs = require('qs');
      //var postobject = {};
      var admin_id = localStorage.getItem('admin_id');
      var postobject = {
          admin_id:  admin_id,
          
      };

      let st_plugin_for = '';
      axios.post(apiUrl+"adminpanel/adminsettings",qs.stringify(postobject)).then(res => {
          if(res.data.results){
              const formpayload = res.data.results;
              st_plugin_for = formpayload.st_plugin_for;              
              this.setState({st_plugin_for:formpayload.st_plugin_for});				
              //console.log(st_plugin_for,'---st_plugin_for');
              
          }
      });
  }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'nav-item has-sub active'
            }else{
            status = 'nav-item has-sub enable';
        }
        return status;
    }
	
  render() {
    //console.log(this.state.st_plugin_for, '--this.state.st_plugin_for');
    const {st_plugin_for} = this.state;
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
  <div className="navbar-header expanded">
    <ul className="nav navbar-nav flex-row position-relative">
      <li className="nav-item mr-auto">
        <a className="navbar-brand" href="#">
        {/* <img src={loginImg} /> */}
          <h3 className="brand-text">Admin</h3>
        </a>
      </li>
      <li className="nav-item d-md-none"><a href="javascript:void(0)" className="nav-link close-navbar mb-side-nav"><i className="ft-x"></i></a></li>
    </ul>
  </div>
  <div className="navigation-background" style={{background: `url(${Background})`}}></div>
  <div className="main-menu-content ps ps--active-y" style={{height: "587px;"}}>
  <Scrollbars className="custom-scroll-wrap">
    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <li className={this.activeMenu('dashboard')}>
        <a href="/dashboard">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Dashboard</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('companylist')}>
		<a title="Company" href="/company">
			<i className="ft-company" aria-hidden="true"></i>
			<span>Company</span>
		</a>
	</li>:''} 
      
   
      <li className={this.activeMenu('pluginowner')}>
        <a title="Plugin Owner" href="/pluginowner">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Admin</span>
        </a>
      </li>

      {st_plugin_for == 'Car' ?
      <>
      <li className={this.activeMenu('user')}>
        <a title="User" href="/user">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Users</span>
        </a>
      </li>
      <li className={this.activeMenu('drivers')}>
        <a title="Driver" href="/drivers">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Drivers</span>
        </a>
      </li>
	    <li className={this.activeMenu('vehicle')}>
        <a title="Vehicle" href="/vehicle">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Vehicle</span>
        </a>
      </li> 
      </>
      : ''}
      {st_plugin_for == 'Bus' ?
      <>
      <li className={this.activeMenu('institute')}>
        <a title="Institutes" href="/institute">
        {/* <a title="Institutes" href="/company"> */}
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Institutes</span>
        </a>
      </li>
      <li className={this.activeMenu('parents')}>
        <a title="Parents" href="/parents">
        {/* <a title="Parents" href="/user"> */}
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Parents</span>
        </a>
      </li>
	    <li className={this.activeMenu('busdrivers')}>
        {/* <a title="Bus Drivers" href="/busdrivers"> */}
        <a title="Bus Drivers" href="/drivers">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Bus Drivers</span>
        </a>
      </li> 
      <li className={this.activeMenu('bushelpers')}>
        {/* <a title="Bus Helpers" href="/bushelpers"> */}
        <a title="Bus Helpers" href="/drivers">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Bus Helpers</span>
        </a>
      </li> 
      </>
      : ''}
      <li className={this.activeMenu('coupon')}>
        <a title="Coupon" href="/coupon">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Coupon</span>
        </a>
      </li>
      <li className={this.activeMenu('offerCms')}>
        <a title="Offer CMS" href="/Cms">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Offers</span>
        </a>
      </li>
      <li className={this.activeMenu('advertisementcategory')}>
        <a title="Advertisement Category" href="/advertisementcategory">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Advertisement Category</span>
        </a>
      </li>
      <li className={this.activeMenu('advertisement')}>
        <a title="Advertisement" href="/advertisement">
          <i className="ft-rehab-users"></i>
          <span className="menu-title" data-i18n="">Advertisement</span>
        </a>
      </li>
     
      {st_plugin_for == 'Car' ?
      <li className={this.activeMenu('popularcity')}>
        <a title="PopularCity" href="/popularcity">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Popular Airports</span>
        </a>
      </li> : ''}
      <li className={this.activeMenu('dashboardmanagement')}>
        <a title="Dashboard Management Builder" href="/dashboardmanagement">
          <i className="ft-tab-management"></i>
          <span className="menu-title" data-i18n="" >Dashboard Management</span>
        </a>
      </li>
      <li className={this.activeMenu('Aifeed')}>
					<a href="/aifeed">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Manage AI Feed</span>
					</a>
				</li>
        <li className={this.activeMenu('GuideCategory')}>
        <a href="/GuideCategory">
          <i className="ft-faq" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Guide Categories</span>
        </a>
      </li>
      <li className={this.activeMenu('GuideSubCategory')}>
        <a href="/GuideSubCategory">
          <i className="ft-faq" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Guide Sub Categories</span>
        </a>
      </li>
      <li className={this.activeMenu('guide')}>
        <a href="/guide">
          <i className="ft-tab-management"></i>
          <span className="menu-title" data-i18n="">Guide</span>
        </a>
      </li>
	 
      <li className={this.activeMenu('fonts')}>
        <a href="/fonts">
          <i className="ft-type"></i>
          <span className="menu-title" data-i18n="">Fonts</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('templates')}>
        <a href="/templates">
          <i className="ft-feather"></i>
          <span className="menu-title" data-i18n="">Templates</span>
        </a>
        </li>:''} 
	    <li className={this.activeMenu('setting')}>
        <a href="/setting">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Settings</span>
        </a>
      </li>
      
    </ul>
    </Scrollbars>
  </div>
</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;