 /*local */ 
// export const apiUrl      = "http://localhost/carbooking-2.0/api/";
// export const baseUrl      = "http://localhost/carbooking-2.0";
// export const mediaUrl      = "http://localhost/carbooking-2.0/media/";
// export const carImageUrl      = "http://localhost/carbooking-2.0/media/carImage/";
// export const sampleFileUrl      = "http://localhost/carbooking-2.0/media/sample_file/";
// export const cmscontentURL       = "http://localhost/carbooking-2.0/media/cmscontent";
// export const tapURL       = "http://localhost/carbooking-2.0/media/tabmanagement";
// export const vehicleURL       = "http://localhost/carbooking-2.0/media/vehicle";

/*Live */

export const apiUrl = "https://admin.jubr.one/carbooking-2.0/api/";
export const baseUrl = "https://admin.jubr.one/carbooking-2.0";
export const mediaUrl = "https://admin.jubr.one/carbooking-2.0/media/";
export const carImageUrl = "https://admin.jubr.one/carbooking-2.0/media/carImage/";
export const sampleFileUrl = "https://admin.jubr.one/carbooking-2.0/media/sample_file/";
export const cmscontentURL = "https://admin.jubr.one/carbooking-2.0/media/cmscontent"; 
export const categoryURL = "https://admin.jubr.one/carbooking-2.0/media/category";
export const tapURL = "https://admin.jubr.one/carbooking-2.0/media/tabmanagement"
export const vehicleURL = "https://admin.jubr.one/carbooking-2.0/media/vehicle";
